<template>
  <div>
    <div v-for="(item, index) in list" :key="index">
      <picker
        label="学历"
        :columns="commonPickerList['eduType']"
        v-model="item.education"
        valueKey="label"
        placeholder="选择学历"
        :isLink="lockStatus"
        @change="changePicker($event, 'education', 'educationList', '', index)"
        :rules="[{ required: true, message: '学历不能为空', trigger: 'onChange' }]"
      ></picker>
    </div>
    <div style="margin: 16px;">
      <van-button @click="handleadd"  round block type="info">增加</van-button>
    </div>
  </div>
</template>

<script>
import picker from './components/picker.vue';
export default {
  components: {
    picker,
  },
  data () {
    return {
      lockStatus: false,
      commonPickerList: {
        eduType: [
          {
            code: '1425',
            id: 18,
            label: '是'
          },
          {
            code: '1426',
            id: 19,
            label: '否'
          }
        ]
      },
      list: [
        {
          education: '1425'
        }
      ]
    };
  },
  methods: {
    changePicker (value, key, objectName, codeName, index) {
      console.log(value, key, objectName, codeName, index);
    },
    handleadd () {
      this.list.push({
        education: '1426'
      });
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
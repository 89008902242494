/*
*  picker选择组件（在vant基础上再次封装）
*  Props说明如下：
*  label：输入框左侧文本；value：v-model绑定值；
*  placeholder：占位提示文字；required：是否显示表单必填星号；
*  inputAlign：输入框对齐方式，可选值为 center right；
*  errorMessageAlign: 错误提示文案对齐方式，可选值为 center right；
*  rules：校验规则；isLink：是否显示右箭头；
*  columns：对象数组，配置每一列显示的数据；
*  valueKey：选项对象中，选项文字对应的键名
*/
<template>
  <div>
    <van-field
    readonly
    clickable
    name="picker"
    :value="showValue"
    :label="label"
    :placeholder="placeholder"
    :required="required"
    :input-align="inputAlign"
    :error-message-align="errorMessageAlign"
    @click="showPicker = true"
    :is-link="!isLink"
    :rules=rules
  />
  <van-popup v-model="showPicker" position="bottom" @opened="openPopup">
    <van-picker
      ref="picker"
      show-toolbar
      v-bind="$attrs"
      :columns="columns"
      @confirm="onConfirm"
      :value-key="valueKey"
      @cancel="showPicker = false"
      :default-index="defaultIndex"
    />
  </van-popup>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'focus'
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Object, Number],
      default: ''
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    required: {
      type: Boolean,
      default: true
    },
    inputAlign: {
      type: String,
      default: 'left'
    },
    errorMessageAlign: {
      type: String,
      default: 'left'
    },
    isLink: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array,
      default: () => {
        return [];
      }
    },
    valueKey: {
      type: [String],
      default: 'id'
    },
    codeName: {
      type: String,
      default: 'code'
    },
    columns: {
      type: Array,
      default: () => {
        return [];
      }
    },
  },
  computed: {
    showValue () {
      // 计算展示内容
      let name = '';
      if (!!this.value || this.value === 0) {
        this.columns.forEach(item => {
          if (item[this.codeName] == this.value) {
            name = item[this.valueKey];
          }
        });
        return name;
      }
      return '';
    }
  },
  data () {
    return {
      showPicker: false,
      defaultIndex: '',
      pickerColumns: []
    };
  },
  methods: {
    onConfirm (value) {
      // v-model 回调
      this.$emit('focus', value[this.codeName]);
      // change 回调
      this.$emit('change', value);
      this.showPicker = false;
    },
    // 弹出层打开时触发函数
    openPopup () {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let that = this;
      if (this.value) {
        this.columns.forEach((item, index) => {
          if (item[this.codeName] == this.value) {
            this.defaultIndex = index;
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>